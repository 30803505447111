<script>
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      valid: true,
      form: {},

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        email: [(v) => !v || /.+@.+/.test(v) || 'E-mail inválido'],
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  async created() {
    if (this.id !== 'new') {
      this.form = await this.fetch();
      delete this.form.id;
      delete this.form.__typename;
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return false;
      if (this.id === 'new') this.insert();
      else this.update();
    },

    async insert() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($customer: customers_insert_input!) {
              insert_customers_one(object: $customer) {
                id
              }
            }
          `,
          variables: {
            customer: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/clientes');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $customer: customers_set_input!) {
              update_customers_by_pk(pk_columns: { id: $id }, _set: $customer) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            customer: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/clientes');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query Customers($id: uuid!) {
              customer: customers_by_pk(id: $id) {
                id
                name
                document_type
                document_number
                contact_name
                contact_email
                contact_phone
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.customer;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Cliente</h1>

    <v-card class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.name"
              label="Nome"
              name="Nome"
              :rules="validations.name"
              :disabled="user.role !== 'manager'"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="form.document_type"
              label="Tipo documento"
              :disabled="user.role !== 'manager'"
              outlined
              persistent-placeholder
              :items="['CPF', 'CNPJ']"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.document_number"
              label="Número documento"
              :disabled="user.role !== 'manager'"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.contact_name"
              label="Contato"
              :disabled="user.role !== 'manager'"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.contact_phone"
              label="Telefone"
              :disabled="user.role !== 'manager'"
              outlined
              persistent-placeholder
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.contact_email"
              :rules="validations.email"
              :disabled="user.role !== 'manager'"
              label="Email"
              type="email"
              outlined
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-spacer />
          <v-btn v-if="user.role === 'manager'" color="primary" plain class="mr-2" to="/clientes">Cancelar</v-btn>
          <v-btn v-if="user.role === 'manager'" color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
